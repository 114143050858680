<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img src="@/assets/images/icons8-micro-96.png" class="h-20 ml-2 inline-block" />
        کانال‌های من
      </h2>
      <p class="mt-3">کانال‌هایی که در آن ادمین هستید</p>
    </template>
    <template #default>
      <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        <div
          v-for="channel in channels"
          :key="channel.id"
          class="px-5 bg-blueGray-100 border-blueGray-200 border rounded-md"
        >
          <h2 class="text-lg font-bold mt-5 mb-3">
            {{ channel.name }}
          </h2>
          <div class="grid grid-cols-2 p-1">
            <div>ID</div>
            <div class="text-left" dir="ltr">
              {{ channel.cid }}
            </div>
          </div>
          <div class="grid grid-cols-2 p-1">
            <div>ممبر</div>
            <div class="text-left" dir="ltr">
              {{ channel.members }}
            </div>
          </div>
          <div class="grid grid-cols-2 p-1">
            <div>ربات</div>
            <div class="text-left" dir="ltr">
              {{ channel.pro_type ? list_pro[channel.pro_type] : "رایگان" }}
              <span v-if="channel.pro_type">({{ channel.pro_exp.days }} روز)</span>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2 mt-2 mb-4">
            <Button
              :to="{
                name: 'ChannelOwnerSetting',
                params: { id: $store.state.user.id, channelId: channel.id },
              }"
              class="h-10 text-lg"
              color="sky"
            >
              تنظیمات
            </Button>
            <Button
              :to="{
                name: 'ChannelOwnerBuy',
                params: { id: $store.state.user.id, channelId: channel.id },
              }"
              class="h-10 text-lg"
              color="green"
            >
              خرید ربات
            </Button>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Button from "@/components/Button.vue";
export default {
  components: { BodySimple, Button },
  data() {
    return {
      firstLoading: true,
      error: [],
      channels: [],
      list_pro: {
        green: "سبز",
        blue: "آبی",
        bronze: "برنزی",
        silver: "نقره‌ای",
        golden: "طلایی",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.$axios
        .get("api/owner-channels", {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channels = response.data.channels;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
  },
};
</script>
